import Link from 'next/link';
import { LeftNavigation } from '@app/config/navigation';

const LeftMenu = () => {

  return (
    <ul className='menu-left'>
      {LeftNavigation.map((nav, index) =>
        <li>
          <Link href={nav.href}>
            <a>
              <span>{nav.title}</span>
              <i className='icon-arrow-right'></i>
              <span className='dropUpArrow'></span>
            </a>
          </Link>
        </li>
      )}
    </ul>
  );
};

export default LeftMenu;
