import { FC } from 'react';
import LoginContainer from '@app/atoms/header/header-top/login-container';
import LangSearchContainer from '@app/atoms/header/header-top/lang-search-container';
import LeftMenu from '@app/atoms/header/header-bottom/left-menu';
import RightMenu from '@app/atoms/header/header-bottom/right-menu';
import MobileMenuHeader from '@app/atoms/header/mobile-menu/mobile-menu-header';

interface IMenuContainer {
  show: boolean;
  onMenuClick: () => void;
}

const MenuContainer: FC<IMenuContainer> = ({ show, onMenuClick }) => {
  return (
    <div className={`headWrapper ${show ? 'active' : ''}`}>
      <div className='wrapperMenu'>
        <MobileMenuHeader
          title='Menu'
          icon='icon-close-icon'
          onClose={onMenuClick}
        />
        {/* <nav className='header-top-nav'>
          <LoginContainer />
          <LangSearchContainer />
        </nav> */}
        <nav className='header-bottom-nav'>
          <LeftMenu />
          {/* <RightMenu /> */}
        </nav>
      </div>
    </div>
  );
};

export default MenuContainer;
