import React, { FC, useEffect, useState } from 'react';

import Link from 'next/link';

import Image from 'next/future/image';

import { Container, Row, Col } from 'react-bootstrap';


import logoWhite from '@public/images/logo_white.png';
import logoBlack from '@public/images/logo_black.png';


import MobileMenu from './mobile-menu';

import MenuContainer from './mobile-menu/menu-container';

import { bgBlur } from '../../utils/cssStyles';



/**

 * React header component

 *

 * @returns JSX

 */

const Header: FC = () => {

  const [showMenu, setShowMenu] = useState(false);

  const [scrolled, setScrolled] = useState(false);



  //Method to handle window scroll

  const handleScroll = () => {

    const position = window.pageYOffset;

    setScrolled(position > 80);

  };



  const toggleMenu = () => {

    setShowMenu(!showMenu);

  };



  //React Hook

  useEffect(() => {

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {

      window.removeEventListener('scroll', handleScroll);

    };

  }, []);



  return (

    <header className={scrolled ? `scrolled header-top-line` : ''}>

      <Container>

        <Row className={`align-items-center align-items-xl-center`}>

          <Col xs={4} md={2}>

            <Link href='/'>

              <a className='logo'>

                <Image

                  src={scrolled ? logoBlack : logoWhite}

                  alt='Wattontech'

                  priority={true}

                  className='img-fluid'

                />

              </a>

            </Link>

          </Col>

          <Col xs={8} md={10}>

            <MobileMenu onMenuClick={toggleMenu} show={showMenu} />

            <MenuContainer onMenuClick={toggleMenu} show={showMenu} />

          </Col>

        </Row>

      </Container>

    </header>

  );

};



export default Header;

