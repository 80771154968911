import React from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import Link from 'next/link';
import { SocialLinks } from '@app/config/navigation';



const SocialIcons = () => {
  return (
    <div className='footer-social-icons-outer'>
      <Container>
        <Row>
          <Col xs={12} md={12} >
            <nav className='footer-social-icons'>
              <ul>
                {SocialLinks.map((el, i) => (
                  <li key={i}>
                    <Link href={el.link}>
                      <a aria-label={el.label}>
                        <i className={el.icon}></i>
                      </a>
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SocialIcons;
