import Animate from '../animations/animate';

export interface IText<E> {
  children: React.ReactNode;
  as?: E;
  italic?: boolean;
  bold?: boolean;
  light?: boolean;
  variant?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  className?: string;
  hasAnimation?: boolean;
  animation?: string;
  delay?: number;
}

type Props<E extends React.ElementType> = React.PropsWithChildren<IText<E>> &
  Omit<React.ComponentPropsWithoutRef<E>, keyof IText<E>>;

const Text = <E extends React.ElementType = 'p'>({
  children,
  as,
  bold,
  light,
  italic,
  className,
  variant,
  hasAnimation,
  animation,
  delay,
  ...props
}: Props<E>) => {
  const Component = as || 'p';
  const classes: Array<string> = [];
  if (bold) classes.push('font-bold');
  if (light) classes.push('font-light');
  if (italic) classes.push('font-italic');
  if (variant) classes.push(`text-${variant}`);
  if (className) classes.push(className);

  if (hasAnimation) {
    return (
      <Animate type={animation} delay={delay}>
        <Component
          {...(classes.length ? { className: classes.join(' ') } : {})}
          {...props}
        >
          {children}
        </Component>
      </Animate>
    );
  }

  return (
    <Component
      {...(classes.length ? { className: classes.join(' ') } : {})}
      {...props}
    >
      {children}
    </Component>
  );
};

export default Text;
