import type { AppProps } from 'next/app';
import { appWithTranslation } from 'next-i18next';
import MainLayout from '../layouts/main';
import { wrapper } from '../store';
import { useRouter } from 'next/router';
import { isRTL } from '@app/utils/translations';
import Head from 'next/head';
import '../styles/style.scss';

const MyApp = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();
  const RTL: boolean = isRTL(router.locale as string);
  return (
    <div dir={RTL ? 'rtl' : 'ltr'} className='mainApp'>
      <Head>
        <title>Wattonetech</title>
      </Head>
      <MainLayout>
        <Component {...pageProps} />
      </MainLayout>
    </div>
  );
};

export default wrapper.withRedux(appWithTranslation(MyApp));
