import { FC } from 'react';

interface IMobileMenuHeader {
  icon: string;
  title: string;
  onClose: () => void;
}

const MobileMenuHeader: FC<IMobileMenuHeader> = ({ title, icon, onClose }) => {
  return (
    <div className='mobileTopBar'>
      <button className='closeMenu' onClick={onClose} aria-label='Close Menu'>
        <i className={icon}></i>
      </button>
      <span className='menuText'>{title}</span>
    </div>
  );
};

export default MobileMenuHeader;
