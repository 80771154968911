import { createSlice } from '@reduxjs/toolkit';

export interface AppState {
  theme: string;
  metadata: {
    title: string;
    description: string;
    keywords: string;
  };
}

const initialState: AppState = {
  theme: 'light',
  metadata: {
    title: '',
    description: '',
    keywords: '',
  },
};

export const AppSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setTheme: (state, action) => {
      localStorage.setItem('theme', action.payload);
      state.theme = action.payload;
    },
    setMetadata: (state, action) => {
      state.metadata = {
        title: action.payload.title || state.metadata.title,
        description: action.payload.title || state.metadata.title,
        keywords: action.payload.title || state.metadata.title,
      };
    },
  },
});

export const { setTheme, setMetadata } = AppSlice.actions;

export default AppSlice.reducer;
