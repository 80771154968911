import { FC } from 'react';

interface IMobileMenu {
  onMenuClick: () => void;
  show: boolean;
}

const MobileMenu: FC<IMobileMenu> = ({ onMenuClick, show }) => {
  return (
    <div className='mobile-menu-bar'>
      <ul>
        {/*<li>
          <Link href='/'>
            <a aria-label='User Login'>
              <i className='icon-user'></i>
            </a>
          </Link>
        </li>*/}
        <li>
          <button onClick={onMenuClick} className={show ? 'active' : ''}>
            <span className='menuText'>Menu</span>
            <span className='menuLines'>
              <i></i>
              <i></i>
              <i></i>
            </span>
          </button>
        </li>
      </ul>
    </div>
  );
};

export default MobileMenu;
