export const LeftNavigation = [
  { title: "Home", icon: "", href: "#" },
  { title: "About Us", icon: "", href: "#about-us" },
  { title: "Services", icon: "", href: "#services" },
  { title: "Process", icon: "", href: "#process" },
  // { title: 'Careers', icon: '', href: '#careers' },
  // { title: 'Blogs', icon: '', href: '#blogs' },
  { title: "Contact Us", icon: "", href: "#contact-us" },
];

export const RightNavigation = [
  {
    title: "Services",
    icon: "",
    isParent: true,
    children: [
      { title: "Web Development", icon: "", href: "#" },
      { title: "App Development", icon: "", href: "#" },
      // { title: 'Game Development', icon: "", href: "#" },
      { title: "eCommerce Technology Development", icon: "", href: "#" },
      { title: "IT Services", icon: "", href: "#" },
      { title: "Managed Services", icon: "", href: "#" },
      { title: "Digital Marketing Services", icon: "", href: "#" },
      // { titel: 'Market Research', icon: "", href: "#" },
      // { titel: 'Healthcare Providers Service', icon: "", href: "#" },
      // { titel: 'Contact Center', icon: "", href: "#" },
      // { titel: 'Game Art Design', icon: "", href: "#" },
      // { titel: 'Business Intelligence & Analytics', icon: "", href: "#" },
    ],
  },
  // {
  //     title: 'Products', icon: '', isParent: true, children: [
  //         { titel: '', icon: "", href: "#" }
  //     ]
  // },
  // {
  //     title: 'Solutions', icon: '', isParent: true, children: [
  //         { titel: '', icon: "", href: "#" }
  //     ]
  // },
  // {
  //     title: 'Consulting', icon: '', isParent: true, children: [
  //         { titel: '', icon: "", href: "#" }
  //     ]
  // },
  // {
  //     title: 'Urgent Help', href: '#', icon: '', isParent: false, children: []
  // },
];

export const SocialLinks = [
  {
    label: "Facebook",
    icon: "icon-facebook",
    link: "https://www.facebook.com/profile.php?id=61551866591989",
  },
  // {
  //   label: "Twitter",
  //   icon: "icon-twitter",
  //   link: "/",
  // },
//   {
//     label: "Youtube",
//     icon: "icon-youtube",
//     link: "/",
//   },
  {
    label: "Linkedin",
    icon: "icon-linkedin",
    link: "https://www.linkedin.com/company/wattone-technologies/",
  },
  {
      label: 'Instagram',
      icon: 'icon-instagram',
      link: 'https://www.instagram.com/wattonetech/',
  },
];
