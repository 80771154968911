import { Col, Container, Row } from 'react-bootstrap';
import Link from 'next/link';
import Image from 'next/image';
import logo from '@public/images/logo_white.png';
import Text from '../typography/text';

const FooterBottom = () => {
  return (
    <div className='footer-bottom'>
      <Container>
        <Row className='align-items-center'>
          <Col>
            <Text variant='xs' className='copywrite-txt'>
              All rights reserved &copy; {new Date().getFullYear()} - Wattone Technologies
            </Text>
          </Col>
          <Col className='footer-bottom-logo'>
            <Link href='/'>
              <a className='logo'>
                <Image
                  src={logo}
                  // src={scrolled ? logoColor : logo}
                  alt='Wattontech'
                  priority={true}
                  className='img-fluid'
                />
              </a>
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FooterBottom;
