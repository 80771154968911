import React from 'react';
import SocialIcons from './socialIcons';
import FooterBottom from './bottom';

const Footer = () => {
  return (
    <footer className='footer-wrapper'>
      <SocialIcons />
      <FooterBottom />
    </footer>
  );
};

export default Footer;
